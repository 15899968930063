import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { useState } from "react";
import ArrowLeft from "../images/arrow_left.svg";
import parse from "html-react-parser";
import News1 from "../../images/news1Desktop.svg";
import { Link } from "gatsby";
import { useQuery } from "@apollo/client";
import { GET_NEWS_BY_ID } from "../query/getNewsById";
import { cmsImageLoader } from "../utils/cmsImageLoader";
import Layout from "../components/Layout";
import ReactMarkdown from "react-markdown";
import { GET_NEWS } from "../query/getNews";
import rehypeRaw from "rehype-raw";

// @ts-ignore
const newsDetailPage = ({ intl, location }) => {
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const { data, loading, error } = useQuery(GET_NEWS, {
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "network-only",
  });

  const dataById = data?.blogs?.data.filter(
    (item: any) => item.attributes.sharedId == id
  );

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA]">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] "></div>
          <Link to="/news">
            <div className="flex mb-[2rem] items-center ">
              <img src={ArrowLeft} alt="" className="" />
              <p className="font-semibold text-[1.25rem] ml-[10px] text-[#001439] cursor-pointer">
                {parse(
                  intl.formatMessage({
                    id: `NewsTumar.HeaderBack`,
                  })
                )}
              </p>
            </div>
          </Link>
          <h2 className="font-semibold text-[1.7rem]">
            {dataById[0].attributes.title}
          </h2>
          <p className="font-regular text-[0.75rem]">12.10.2022</p>
          <div className="mt-[2rem] w-auto rounded-[1rem] ">
            <img
              src={cmsImageLoader({
                src: `${dataById[0].attributes?.image?.data?.attributes?.url}`,
                width: 0,
              })}
              alt=""
              className=""
            />
          </div>
          <p className="mt-[2rem] font-medium text-[1rem]">
            <ReactMarkdown
              linkTarget="_blank"
              skipHtml={true}
              rehypePlugins={[rehypeRaw as any]}
            >
              {dataById[0].attributes.body.replaceAll(
                "/uploads",
                `${process.env.STRAPI_BASE_URL}/uploads`
              )}
            </ReactMarkdown>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(newsDetailPage);
